import React from 'react'
import IntegrationTemplate from '../../components/Integrations/IntegrationTemplate'

import expressionEngineLogo from '../../images/integration-logo-images/expressionengine.png'

const ExpressionEngine = () => {
    return (
        <>
            <IntegrationTemplate 

                metaTitle="Integrate Optinly with Expression Engine"
                metaDescription="Optinly is the smart pop-up builder for your Expression Engine store. With Optinly, you can seamlessly attract leads to grow your email list and reduce cart abandonment."

                otherProductLogo={expressionEngineLogo} //PLEASE IMPORT THE LOGO HERE LIKE THIS

                integrationApp="Expression Engine"
                homeTitle="Grow Your Email List with Expression Engine Popups"
                homeDescription="Integrate Optinly with your Expression Engine store. Ideal lead generation tool with visitor engaging popups to grow your email list. Increase Sales with smart popups. "

                docsTitle="Increase your Expression Engine store email list "
                docsDescription="Best Expression Engine popup app. A complete package to rapidly grow your email list, leads and subscribers. Select from pre-made popups or custom popup in your Expression Engine store. "

                featruesDocsSection1="Integeration and Customization "
                featruesDocsTitle1="Seamless Integeration and Advance Customization Options"
                featruesDocsDescription1="Customize popups based on user behavioural pattern. Design or development skill not required to customize popup. Expression Engine email popup through simple integeration. "

                featruesDocsSection2="Installation and Signup"
                featruesDocsTitle2 ="Expression Engine Popups To Improve Store Performance"
                featruesDocsDescription2="Hassle-free signup, download, and installation. Best Expression Engine popup form to deliver customer-friendly popup. Select the best email popup for Expression Engine from numerous existing templates."

                featruesDocsSection3="Achieve Marketing Goals "
                featruesDocsTitle3 ="One-stop Solution for Expression Engine Popup building"
                featruesDocsDescription3="Everything you need to create user engaging popups in one place. Advacned dashboard, simple integeration, regular update of pre-made templates and a clear user interface."

                featruesDocsSection4="Detailed Dashboard Analytics"
                featruesDocsTitle4 ="All-in-one Dashboard"
                featruesDocsDescription4="Know how your Expression Engine Popup is performing. Manage campaigns, make changes, and more with a powerful dashboard. Simple to navigate yet highly accurate analytics available."

                FAQQuestion1="Does Optinly offer Expression Engine email popup?  "
                FAQAnswer1="Optinly offers multiple redaymade Expression Engine email popups. Also, it provides options to easily customize the popup depending upon the user. Expression Engine email popups are popups used in Expression Engine websites for collecting quality customer email address. "

                FAQQuestion2="How many types of Expression Engine popup form is available in Optinly?"
                FAQAnswer2="Optinly offers 6+ types of unique popup forms for Expression Engine. Each one is engaging and grabs audience attentation without any delay. "

                FAQQuestion3="Is there a free pricing plan available in Optinly for Expression Engine stores? " 
                FAQAnswer3="Optinly have a free pricing plan that works on Expression Engine stores. Feel free to check out the entire pricing structure in Optinly pricing page.  "

                FAQQuestion4="Is Expression Engine exit popup available in Optinly? "
                FAQAnswer4="Yes, Optinly have customer engaging exit popup templates. You can personalize the template or made use of the pre-made one. "
            />
        </>
    )
}

export default ExpressionEngine
